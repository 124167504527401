.iframePlaceholder {
    width: 300px !important;
    height: 533px !important;
    position: relative;
    background-color: rgba(0, 0, 0, 0.5);
}

.iframePlaceholderLandscape {
    width: 533px !important;
    height: 300px !important;
    position: relative;
    background-color: rgba(0, 0, 0, 0.5);
}

.previewText {
    color: rgba(255, 255, 255, 0.5);
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: center;
}

.iframeContainer {
    width: 300px !important;
    height: 533px !important;
    position: relative;
    padding-bottom: 0;
}

.iframeContainerLandscape {
    width: 533px !important;
    height: 300px !important;
    position: relative;
    padding-bottom: 0;
}

// hard break-points to avoid issues with scaling container
@media (max-width: 800px) {
    .iframeContainerLandscape,
    .iframePlaceholderLandscape {
        width: 400px !important;
        height: 225px !important; 
    }
}

@media (max-width: 450px) {
    .iframeContainerLandscape,
    .iframePlaceholderLandscape {
        width: 300px !important; 
        height: 169px !important; 
    }
}

.responsiveIFrame {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }