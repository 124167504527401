.mainManager {
    min-width: 30px;
}

.disabledHover {
    pointer-events: none;
}

.smallText {
    font-size: 0.7rem; 
}

.d-flex {
    flex-wrap: wrap;
    margin-right: auto;
}

.funnelButton, .updateButton {
    margin-top: 0;
    margin-bottom: 0;
    height: 30px;
    padding: 2px;
}

.funnelButton {
    width: 30px;
}

.playButton {
    height: 30px;
    padding-bottom: 2px;
    padding-top: 2px;
    padding-left: 6px;
    padding-right: 6px;
    margin-right: 0.25rem;
}

.updateButton {
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 3px;
    font-size: 14px;
    margin-left: 6px;
}

.datePickerContainer {
    flex-basis: 100%;
  }
  
.analyticsLastUpdate {
    margin-left: 5px;
    margin-top: 1px;
    color: rgb(154, 154, 154);
}
  