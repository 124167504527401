//Context menu general styling starts 
.dropdownTitle {
    font-weight: 500;
    font-size: 0.85rem;
}
  
.dropdownItemText {
    font-size: 0.9rem;
    background: none;
    border: none;
    cursor:auto;
    
    &:after {
      display: none !important;
    }
    &:hover, &:focus, &:active {
      background-color: transparent !important;
      border: none !important; 
      outline: none !important; 
  }
}
  
.priorityLabel {
    padding-left: 0.2rem;
    padding-right: 0.4rem;
}

.dropdownReset {
  margin-bottom: 0.4rem;
  margin-top: 0.3rem;
  font-size: 0.9rem;
    &:focus,
    &:active {
      background-color: inherit !important;
    }
    &:hover {
      background-color: inherit !important;
      color:#4084fa !important;
    }
}

.invisibleButton {
      background: none;
      border: none;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0.2rem;
      padding-right: 0.1rem;
      margin: 0;
      cursor: pointer;
      
      &::after {
        display: none !important;
      }
  
      &:hover, &:focus, &:active {
        background-color: transparent !important;
        border: none !important; 
        box-shadow: none !important; 
        outline: none !important; 
    }
}
  
.size {
  font-size: 0.85rem;
  padding-right: 0.2rem;
  font-weight: 400;
  border-radius: 0.2rem;
}
  
.ellipsisVertical {
  padding-right: 0.2rem;
}
  
  // Context menu item styling starts
.sortItemLabel {
  padding-left: 0.35rem;
  padding-right: 0;
  cursor: grab;
}
  
.resetButton {
    margin-bottom: 1rem;
}
  
.sortItemIcon {
  cursor:pointer;
    &:hover {
        color:#4084fa !important;
    }
}
  
.dropdownItemContainer {
  width: 12rem;
}

.removeIcon {
  cursor: pointer; 
    &:hover {
      color:#4084fa !important;
    }
}
  
.superscript {
  margin-left: 0.1rem;
}
  
.dottedLine {
  border-bottom: 2px dotted;
  width: 98%;  
  padding-top: 0.3rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.6rem;
}