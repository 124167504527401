.selectedText {
  text-decoration: underline;
  font-weight: bold;
}

.sortingButton, .createNew {
  height: 30px;
  padding: 2px;
  padding-bottom: 4px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 6px;
}

.eventsDropdown {
  max-height: 350px;
  overflow-y: auto;
} 

.dropdownItem:hover,
.dropdownItem:active,
.dropdownItem:focus {
  background-color: #80b3ffb2;
}

.dropdownButton {
  background: transparent;
  border: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}