.variationItemsContainer {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}

.editableVariableItemsContainer {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 95%;
    max-width: 100%;
    max-height: 95%;
}

.maxSize {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.aditorViewSmall {
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 100%;
    height: calc(100vh - 130px);
}

.aditorViewFull {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 99%;
    z-index: 9999;
}

.fullSize {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.fullSizeChild {
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
}

@media (max-width: 54rem) {
    .widthProductsAnalytics {
        min-width: 54rem;
    }
    .widthProducts {
        min-width: 46rem;
    }
}

@media (max-width: 50rem) {
    .widthBuildReportAnalytics {
        min-width: 50rem;
    }
    .widthBuildReport {
        min-width: 40rem;
    }
}

@media (max-width: 62.5rem) {
    .editPanelClientView {
        min-width: 100%;
    }
}
@media (max-width: 43.75rem) {
    .editPanelClientView {
        min-width: 42.5rem;
    }
}

@media (max-width: 38rem) {
    .minWidthEvents {
        min-width: 32rem;
    }
}

.maxWidth {
    max-width: 100%;
}

.search-products-form {
    max-width: 350px;
    height: 30px;
    font-size: 14px;
    padding-bottom: 8px;
    padding-left: 8px;
}

.codeEdit {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    min-width: 100% !important;
    min-height: 500px !important;
}

.designerView {
    max-width: 55rem;
}