.bigBadge {
    font-size: 0.9em;
    padding: 6px;
}

.dateInput {
    flex: 1;
    max-width: 110px;
    height: 30px;
    font-size: 12px;
    padding-right: 5px;
    padding-bottom: 7px;
    padding-left: 9px;
}

.d-flex {
    flex-wrap: wrap;
}

.analyticsText {
    font-size: 0.8rem;
    flex-wrap: nowrap;
}
