.responsiveContainer {
    width: 100%;
  }

  @media (min-width: 68rem) {
    .responsiveContainer {
      width: 95%;
    }
  }
  @media (min-width: 87rem) {
    .responsiveContainer {
      width: 92%;
    }
  }

  @media (min-width: 102rem) {
    .responsiveContainer {
      width: 88%;
    }
  }

.backButton {
    padding: 1.5rem 1rem 0rem 1.25rem;
}
