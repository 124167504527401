.editPanel {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow-x: hidden;
}

.backButton {
    padding: 1rem;
}

.downloadButton {
    margin-top: 0.2rem;
    margin-right: 0.3rem;
    width: 2.6rem; 
    height: 2.2em;
    align-items: center; 
    justify-content: center;
    text-align: center;
}

.downloadIcon {
    padding-bottom: 0.15rem;
}

.navLink {
    padding: 4px 10px 5px 11px;
}

.maximizeButton {
    padding: 2px 8px 2px 8px;
}

.minHeight {
    min-height: 2.5rem;
}