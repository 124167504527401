.noWrap {
    white-space: nowrap;
}

.largePopover {
    max-height: 400px;
    overflow: auto;
}

.stickySearch {
    position: sticky;
    top: 0;
    z-index: 1;
    margin-bottom: 1rem;
}

.popoverDark {
    background-color: #4c4c4c;
    color: #fff;

    .popover-header {
        border: none;
    }

    .popover-body {
        background-color: #333;
        color: #fff;
    }

    .btn-primary {
        background-color: #0056b3;
        border-color: #0056b3;
    }

    .btn-secondary {
        background-color: #6c757d;
        border-color: #6c757d;
    }

    &::-webkit-scrollbar {
        background-color: #363636;
        width: 0.4rem;   
    }

    &::-webkit-scrollbar-track {
        background-color: #4c4c4c;
        border-radius: 0.25rem;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #a1a1a1;
        border: 3px solid #989898;
        border-radius: 0.25rem;
    } 
}