.settingsItem {
    padding: 0 1rem 1rem 1rem;
    margin: 0.5rem 1rem 1rem 1rem;
    width: 90%;
    max-width: 1200px;
}

.settingsItemClient {
    padding: 0 1rem 1rem 1rem;
    margin: 0.5rem 1rem 1rem 1rem;
    width: 100%;
    max-width: 1200px;
}