.search-tags {
    margin-bottom: 0.5rem;
    border: transparent;
    padding: 4px 2px;
    border-radius: 6px;
}

.search-tag-btngroup .btn {
    width: 88px;
    padding-bottom: 6px;
}

.tags-card-body {
    padding: 0.5rem 1rem 0;
}