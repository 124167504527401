.titleLink {
    text-decoration: none;
    color: inherit;
}
.titleLink:hover {
    color: #4084fa;
    cursor: pointer;
}

.playableLink:hover {
    color: #4084fa;
    cursor: pointer;
}