.cardBodyContainer {
    container-type: inline-size;
    container-name: card-body;
}

.colDateCustom {
    flex: 0 0 100%;
}

.colBtnCustom {
    flex: 0 0 100%;
    margin-top: 0.5rem;
    text-align: end;
}

@container card-body (min-width: 430px) {
    .colDateCustom {
        flex: 0 0 65%;
    }
    .colBtnCustom {
        flex: 0 0 35%;
        margin-top: 0;

    }
}