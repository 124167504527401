.editableCard {
    padding: 5px;
    margin: 5px;
    width: 280px;
    max-width: 100%;
}

.editable-card-modal {
    padding: 8px;
    margin: 8px;
    width: 100%;
}

.horizontally-scrollable-view {
    overflow-x: auto;
}

.vertically-scrollable-view {
    overflow-y: auto;
}

.popover-large {
    height: 200px;
}

.no-border-button {
    border: 0;
}

.checkbox-width {
    width: 2rem;
}

.client-card-big {
    min-width: 260px;
    max-width: 260px;
}

.client-card-big-name {
    margin-right: 0.5rem;
}

.client-card-small {
    min-width: 210px;
    max-width: 210px;
    border: none;
}

.client-card-localization {
    min-width: 210px;
    max-width: 210px;
}

.dragging-border {
    border: 1px solid #2680fffc !important;
    transform: scale(1.03);
    z-index: 1000;
}

.mouse-grab {
    cursor: grab;
}

.mouse-grab:active {
    cursor: grabbing;
}

.draggable-item {
    transition: all 0.2s ease;
}

.dragging-element {
    transform: scale(1.03);
}

.reduced-opacity {
    opacity: 0.6;
    transition: opacity 0.2s ease;
}

.developer-network-card {
    min-width: 360px;
    max-width: 360px;
}