@import "../../sass/base/_base.scss";

.buttonWrapper {
    display: flex;
    align-items: center;
    margin-top: 0.1rem;
}

.closeButton {
    margin-left: 0.16rem;
    cursor: pointer;
    font-size: 0.7rem;
    margin-top: -0.73rem;
    font-weight: 700;
    &:hover {
        color:#4084fa;
    }
}

.indexNumber {
    padding-right: 0.1rem;
}

.faSortIcon {
    padding-bottom: 0.01rem;
    padding-left: 0.01rem;
}

.sortButton {
    border-radius: 25%; 
    width: auto;
    max-width: 1.25rem;
    min-width: 1rem;
    height: 1rem;
    padding-top: 0rem;
    padding-left: 0.1rem;
    padding-right: 0.18rem;
    padding-bottom: 0.8rem;
    background-color:  #4084fa;
    color: white;
    font-size: 0.62rem;

}

.centeredTitle {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 0;
}

.sortModal {
    font-size: 0.9rem;
}