.alertModal {
    text-align: center;
    margin-top: 0.6rem;
    margin-bottom: -3rem;
    padding: 0.5rem;
}

.clientBackButton {
    margin: 1rem 0.5rem 0rem;
    padding: 3px 8px 5px ;
}

.backButton {
    padding: 1rem;
}

.createBuild {
    padding: 2px 9px 3px 9px;
    margin-top: 7px;
}

.cardHeaderOptions {
    padding: 0.4rem 1rem 0.2rem 1rem;
}

.optionsChevron {
    padding: 0.3rem 0 0.2rem 0.6rem;
    cursor: pointer;
}

.jsonView {
    width: 100%;
    height: calc(100vh - 195px);
}

.mutatorView {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.mutatorOptions {
    margin: 1rem;
    width: 100%;
    min-width: 600px;
    height: auto;
    @media (min-width: 1535px) {
        width: 60%;
        min-width: 1000px;
        height: auto;
    }
}
