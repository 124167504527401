.userPicture {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 2px; 
}

@media (max-width: 36rem) {
    .menuAlignment {
        right: auto !important;
        left: 0 !important;
    }
    }