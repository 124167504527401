.responsiveContainer {
    width: 100%;
  }

  @media (min-width: 42rem) {
    .responsiveContainer {
      width: 95%;
    }
  }
  
  @media (min-width: 55rem) {
    .responsiveContainer {
      width: 85%;
    }
  }
  
  @media (min-width: 68rem) {
    .responsiveContainer {
      width: 75%;
    }
  }
  @media (min-width: 87rem) {
    .responsiveContainer {
      width: 65%;
    }
  }

  @media (min-width: 102rem) {
    .responsiveContainer {
      width: 55%;
    }
  }