.container {
    container-type: inline-size;
}

.selectionToggleCol,
.title {
    flex: 0 0 92%;
}

.analyticsCol {
    display: none;
    flex: 0 0 0%;
}

.infoIconCol {
    flex: 0 0 8%;
    text-align: end;
    color: gray;
    padding-right: 0.5em;
}

.angles {
    text-align: end;
    color: gray;
    flex: 0 0 8%;
}

@container (min-width: 750px) {
    .selectionToggleCol {
        flex: 0 0 54%;
    }

    .analyticsCol {
        display: block;
        flex: 0 0 20%;
    }

    .infoIconCol {
        flex: 0 0 6%;
        text-align: end;
        color: transparent;
    }

    .angles {
        color: transparent;
    }
    
}
