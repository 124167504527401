body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root, #app, #app>div {
  height: 100%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.dropdown-item:hover,
.dropdown-item:active,
.dropdown-item:focus {
  background-color: #80b3ffb2;
}

.btn {
  color: white !important;
  border-radius: 5px;
}

.btn:hover,
.btn:focus,
.btn:active {
  color: white !important;
}

.mouseHover {
  cursor: pointer;
}

.no-border {
  border: none !important;
}

.badge {
  padding: 0.2rem 0.4rem 0.3rem 0.35rem;
}

.mouseHoverLink:hover {
  text-decoration: underline;
}

.text-underline {
  text-decoration: underline;
}

.align-start-onwrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.image-fullsize img {
  width: 100%;
}

.accordion > .card > .card-header {
  margin-bottom: 0 !important;
}

.accordion > .card:not(:first-of-type) {
  border-bottom-style: solid !important;
  border-radius: 0.25rem !important;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 0.25rem !important;
}

.analytics-circle {
    display: flex;
    width: 10px;
    height: 10px;
    background-color: #00fdcb;
    border-radius: 50%;
    border-color: #000000;
}

.analytics-square {
    display: flex;
    width: 40px;
    height: 11px;
    background: rgb(34,193,195);
    background: linear-gradient(90deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
}

.analytics-circle-multi {
    display: flex;
    width: 10px;
    height: 10px;
    background: linear-gradient(90deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
    border-radius: 50%;
    border-color: linear-gradient(90deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
}

.analytics-square-multi {
    display: flex;
    width: 40px;
    height: 11px;
    background: linear-gradient(90deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
}

.xs-font {
  font-size: x-small;
}

.analytics-font {
  font-size: small;
}

.bread-text {
  font-size: 14px;
}

.medium-font {
  font-size: 0.95em;
}

::-webkit-scrollbar-track {
  background-color: #cfcfcf;
}
    
::-webkit-scrollbar {
    width: 0.5rem;
    background-color: #7a7a7a;
}
    
::-webkit-scrollbar-thumb {
  background-color: #939393; 
  border-radius: 10px;
}

.no-wrap {
  flex-wrap: nowrap;
}

.badge {
  color: white !important;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
  padding-right: 0.5rem;
}

.overflow-hidden {
  overflow: hidden;
}

::placeholder {
  color: #7a7a7ab5 !important;
}

.tooltip {
  position: fixed;
}