.container {
    container-type: inline-size;
}

.title {
    flex: 0 0 90%;
}

.angles {
    flex: 0 0 8%;
    text-align: end;
    color: gray;
}

@container (min-width: 600px) {
    .angles {
        color: transparent;
    }
}