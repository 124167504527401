.text {
    font-weight: 500;
}

.editIcon {
    padding-left: 0.6rem;
}

.editIcon:hover {
    color: #4084fa !important;
}

.eventForm {
    align-items: center;
    display: inline-block;
    padding: 2px 8px 3px 9px;
}

.eventDropdown {
    padding: 2px 8px 0 9px;
}

.excludeContainer {
    container-type: inline-size;
    container-name: exclude-container;
}

.excludeLabel {
    display: none;
}

@container exclude-container (min-width: 280px) {
    .excludeLabel {
        display: inline;
    }
}