.AtoZ {
  margin-right: 0.2rem;
  font-size: 0.7rem;
}

//Hide default input
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  margin-left: 0.2rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ccc;
}

.checkmark {
  position: relative;
  height: 0.7rem;
  width: 0.7rem;
  background-color: #343a40;
  border: 1px solid #888888;
  border-radius: 0.1rem;
  margin-top: 0.5rem;
}

.container:hover input ~ .checkmark {
  background-color: #dadada; 
}

.container input:checked ~ .checkmark {
  background-color: #4084fa; 
}

.lightmodeCheckmark .checkmark {
  background-color: #f4f4f4;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 0.4rem;
  top: 0.25rem;
  width: 0.3rem;
  height: 0.6rem;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.collapseButton {
  padding: 0rem;
  margin-top: -0.25rem;
}

.collapseCaret {
  margin-right: 0.75rem;
  margin-bottom: 0.05rem;
  font-size: 0.7em;
  &:hover {
    color: #4084fa !important;
  }
}

.marginToCounterSuperscript {
  padding-right: 0.15rem;
}

.superscript {
  padding: 0;
  margin-left: -0.2rem;
  margin-right: 0.1rem;
  margin-bottom: -0.3rem;
  font-size: 1.1rem;
}
