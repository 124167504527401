.fakeDisabledButton {
  pointer-events:none
}

.footerInfo {
  padding: 0.4rem 1rem 0.5rem 1rem;
}

.cardHeader {
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.cardBody {
  padding: 1rem 1rem 1rem 1rem;
  min-width: 12rem;
}

.customObjectCardBody {
  padding: 1rem 1rem 0.5rem 1.5rem;
}

.fieldCardBody {
  padding: 1rem 1rem 1rem 1rem;
}

.headerText {
  font-weight: 500;
}
