.theme-light {

  background-color: white;

  .primary-background {
    background-color: white;
  }

  .secondary-background {
    background-color: #B0BEC5;
  }

  .btn-outline-info:hover {
    color: black;
  }

  .text-inverse {
    color: black !important;
  }
  
  .text-inverse-75 {
    color: rgba(0, 0, 0, 0.75);
  }

  .text-inverse-50 {
    color: rgba(0, 0, 0, 0.5) !important;
  }

  .text-inverse-25 {
    color: rgba(0, 0, 0, 0.256) !important;
  }

  .custom-input-background > .input-group-text {
    background: whitesmoke;
    border-style: none;
  }

  .nav-tabs .nav-link {
    color: rgba(0, 0, 0, 0.6);
  }

  .nav-tabs .nav-link.active {
    color: rgb(0, 0, 0);
  }

  .td-inverse-highlight:hover {
    color: #007bff !important;
  }

  .inner-card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .custom-input-background > .input-group-text {
      background: whitesmoke;
      border-style: none;
    }
  }

  ::-webkit-scrollbar-track {
    background-color: #fdfdfd;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: #7a7a7a;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c1c9d0; 
    border-radius: 10px;
  }

  .scrollable-container {  
    &::-webkit-scrollbar {
      width: 0.2rem;
    }
  }
  
  .sticky-header {
    background: #FFF;
  }
  
  .dropdown-toggle::after {
    display: none;
  }
  
  .list-row:nth-child(odd) {
    background-color: rgb(240, 240, 240) !important;
  }

  .list-row:nth-child(even) {
    background-color: rgb(230, 230, 230) !important;
  }
}
