.consentBanner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #252525;
    color: #fff;
    padding: 1rem;
    text-align: center;
    z-index: 10000;
  }
  
  .button {
    margin: 0 0.5rem;
    width: 6rem;
  }