.resultsPanel {
    margin: 1px 0 0;
    padding: 20px 10px 10px;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.resultsPanelButtons {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
}

.resultsPanelMain {
    margin: 0;
    padding: 10px;
    width: 100%;
    height: calc(100vh - 126.72px); // 58.72 + 31
}

.emptyPanel {
    margin: 0;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.buildToast {
    bottom: 150px;
    left: 5px;
    right: 5px;
    z-index: 100;
}

.loadingToast {
    bottom: 25px;
    left: 5px;
    right: 5px;
    z-index: 100;
}