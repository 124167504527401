.upButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  z-index: 1000;
  opacity: 0.2;
  &:hover {
    opacity: 0.6;
  }
}
